import React from "react";
import PropTypes from "prop-types";

import PageLayout from "../pagelayout";

const BlogLayout = ({ children, isPost }) => {
  return (
    <PageLayout
      subTitle="Blog"
      subtitleHref="blog"
      isPost={isPost}
      hiddenFooterLinks={["blog"]}
    >
      {children}
    </PageLayout>
  );
};

BlogLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isPost: PropTypes.bool,
};

BlogLayout.defaultProps = {
  isPost: false,
};

export default BlogLayout;
