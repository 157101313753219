import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";

import * as styles from "./scss/header.module.scss";

const Header = ({ siteTitle, siteSubTitle, isPost, subtitleHref }) => {
  const TitleTag = isPost ? "div" : "h1";
  const SubTitleTag = isPost ? "div" : "h2";
  const hasSubtitleHref = isPost && !!subtitleHref;

  return (
    <header className={styles.header}>
      <TitleTag className={styles.siteTitle}>
        <Link to="/">{siteTitle}</Link>
      </TitleTag>

      <SubTitleTag className={styles.siteSubTitle}>
        {hasSubtitleHref ? (
          <Link to={`/${subtitleHref}`}>{siteSubTitle}</Link>
        ) : (
          siteSubTitle
        )}
      </SubTitleTag>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  siteSubTitle: PropTypes.string.isRequired,
  subtitleHref: PropTypes.string,
  isPost: PropTypes.bool.isRequired,
};

export default Header;
