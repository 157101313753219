import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import * as styles from "./scss/footer.module.scss";

const Footer = ({ className, hiddenLinks }) => (
  <footer className={`${styles.footer} ${className}`}>
    <div className={styles.socialLinks}>
      {!hiddenLinks.includes("blog") ? (
        <Link to="/blog" className={styles.socialLink}>
          Blog
        </Link>
      ) : null}
      {!hiddenLinks.includes("projects") ? (
        <Link to="/projects" className={styles.socialLink}>
          Projects
        </Link>
      ) : null}
      {!hiddenLinks.includes("open-source") ? (
        <OutboundLink
          href="https://dev.maroun-baydoun.com/"
          className={styles.socialLink}
          rel="me"
          eventLabel="dev.maroun-baydoun"
        >
          Open source
        </OutboundLink>
      ) : null}
      {!hiddenLinks.includes("twitter") ? (
        <OutboundLink
          href="https://twitter.com/maroun_baydoun"
          className={styles.socialLink}
          rel="me external noopener noreferrer"
          target="_blank"
          eventLabel="Twitter profile"
        >
          Twitter
        </OutboundLink>
      ) : null}
      {!hiddenLinks.includes("github") ? (
        <OutboundLink
          href="https://github.com/maroun-baydoun"
          className={styles.socialLink}
          rel="me external noopener noreferrer"
          target="_blank"
          eventLabel="Github profile"
        >
          Github
        </OutboundLink>
      ) : null}
      {!hiddenLinks.includes("linkedin") ? (
        <OutboundLink
          href="https://www.linkedin.com/in/marounbaydoun"
          className={styles.socialLink}
          rel="me external noopener noreferrer"
          target="_blank"
          eventLabel="Linkedin profile"
        >
          Linkedin
        </OutboundLink>
      ) : null}
      {!hiddenLinks.includes("instagram") ? (
        <OutboundLink
          href="https://www.instagram.com/marounbaydoun/"
          className={styles.socialLink}
          rel="me external noopener noreferrer"
          target="_blank"
          eventLabel="Instagram profile"
        >
          Instagram
        </OutboundLink>
      ) : null}
    </div>
  </footer>
);

Footer.propTypes = {
  className: PropTypes.string,
  hiddenLinks: PropTypes.arrayOf(
    PropTypes.oneOf([
      "blog",
      "projects",
      "open-source",
      "twitter",
      "linkedin",
      "instagram",
    ])
  ),
};

Footer.defaultProps = {
  className: "",
  hiddenLinks: [],
};

export default Footer;
