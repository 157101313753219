import React from "react";
import { graphql } from "gatsby";

import BlogLayout from "../components/bloglayout";
import BlogPost from "../components/blogpost";
import Seo from "../components/seo";
import BackLink from "../components/backlink";

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fields: { sourceName: { eq: "blog" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;

const BlogPage = ({ data }) => (
  <BlogLayout>
    <Seo title="Blog" />
    {data.allMarkdownRemark.edges.map((post) => {
      const {
        node: {
          frontmatter: { title, date, description },
          fields: { slug: path },
        },
      } = post;

      return (
        <BlogPost
          title={title}
          date={date}
          description={description}
          key={`${date}__${title}`}
          path={path}
        />
      );
    })}
    <BackLink to="/" text="Back Home" />
  </BlogLayout>
);

export default BlogPage;
