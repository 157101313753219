import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import * as styles from "./scss/backlink.module.scss";

const BackLink = ({ to, text }) => (
  <Link to={to} rel="home" className={styles.backLink}>
    {text}
  </Link>
);

BackLink.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default BackLink;
