import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import * as styles from "./scss/blogpost.module.scss";

const BlogPost = ({ title, date, description, path }) => (
  <article className={styles.blogPost}>
    <header className={styles.blogPostHeader}>
      <h3 className={styles.postTitle}>
        <Link to={path}>{title}</Link>
      </h3>
      <p className={styles.postDate}>{date}</p>
    </header>
    <p className={styles.postDescription}>{description}</p>
  </article>
);

BlogPost.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default BlogPost;
