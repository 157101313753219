import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import Footer from "../footer";
import * as styles from "./scss/pagelayout.module.scss";

const PageLayout = ({
  children,
  subTitle,
  subtitleHref,
  isPost,
  hiddenFooterLinks,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQueryPageLayout {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const {
    site: {
      siteMetadata: { title },
    },
  } = data;

  return (
    <div className={[styles.page, ...(isPost ? [styles.post] : [])].join(" ")}>
      <Header
        siteTitle={title}
        siteSubTitle={subTitle}
        subtitleHref={subtitleHref}
        isPost={isPost}
      />
      <main>
        <div className={styles.mainContainer}>{children}</div>
      </main>
      <Footer className={styles.footer} hiddenLinks={hiddenFooterLinks} />
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  subTitle: PropTypes.string.isRequired,
  subtitleHref: PropTypes.string,
  isPost: PropTypes.bool,
  hiddenFooterLinks: PropTypes.arrayOf(
    PropTypes.oneOf(["blog", "projects", "twitter", "linkedin", "instagram"])
  ),
};

PageLayout.defaultProps = {
  isPost: false,
  className: "",
};

export default PageLayout;
